import { createLogic } from "redux-logic"
import auth0 from "auth0-js"
import { navigate } from "@reach/router"

import { load as loadCapabilities } from "flows/capabilities/actions"
import { load as loadServiceRoles } from "flows/roles_per_service/actions"

import {
    LOADED_AUTH,
    LOADING_AUTH_FAILED,
    LOADING_AUTH_ERROR,
} from "./action_types"

const handleAuthentication = createLogic({
    type: LOADED_AUTH,

    async transform({ getState }, next) {
        // WebAuth adds properties to the config object, but we have an immutable object
        // so we must make a copy.
        const auth = new auth0.WebAuth({ ...getState().config })

        auth.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()

                next({
                    type: LOADED_AUTH,
                    payload: {
                        accessToken: authResult.accessToken,
                        idToken: authResult.idToken,
                        expiresAt: expiresAt,
                    },
                })

                navigate("/")
            } else if (err) {
                next({
                    type: LOADING_AUTH_ERROR,
                    error: err,
                })
            } else {
                next({
                    type: LOADING_AUTH_FAILED,
                })
            }
        })
    },
})

const whenAuthenticated = createLogic({
    type: LOADED_AUTH,

    process(_, dispatch, done) {
        // Load all service roles, e.g. that Transit Build has role Customer (and more).
        dispatch(loadServiceRoles())

        // Load capabilities. These determine what the current user can do in the UI.
        dispatch(loadCapabilities())
        done()
    },
})

export default [handleAuthentication, whenAuthenticated]
