import { combineReducers, applyMiddleware, createStore, compose } from "redux"
import { createLogicMiddleware } from "redux-logic"

import { LOAD_ROLES } from "./flows/roles_per_service/action_types"
import { LOAD_CONFIG } from "./flows/config/action_types"
import { default as authReducer } from "./flows/authentication/reducer"
import { default as authLogic } from "./flows/authentication/logic"
import { default as activeFiltersReducer } from "./flows/active_filters/reducer"
import { default as usersReducer } from "./flows/users/reducer"
import { default as rolesReducer } from "./flows/roles_per_service/reducer"
import { default as rolesLogic } from "./flows/roles_per_service/logic"
import { default as configLogic } from "./flows/config/logic"
import { default as usersLogic } from "./flows/users/logic"
import { default as searchReducer } from "./flows/search/reducer"
import { default as modalReducer } from "./flows/modal/reducer"
import { default as messagesReducer } from "./flows/messages/reducer"
import { default as configReducer } from "./flows/config/reducer"
import { default as capabilitiesReducer } from "./flows/capabilities/reducer"
import { default as capabilitiesLogic } from "./flows/capabilities/logic"

const deps = {
    domain: "roxtec-dev.eu.auth0.com",
    client_ID: "3RGQXPnGMlBq6t2LUBOa7xOWqJwTqb3P",
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// Exposed for testing
export function newStore() {
    const rootReducer = combineReducers({
        auth: authReducer,
        active_filters: activeFiltersReducer,
        users: usersReducer,
        roles_per_service: rolesReducer,
        modal: modalReducer,
        search: searchReducer,
        messages: messagesReducer,
        config: configReducer,
        capabilities: capabilitiesReducer,
    })

    const logicMiddleware = createLogicMiddleware(
        [...rolesLogic, ...authLogic, ...usersLogic, ...configLogic, ...capabilitiesLogic],
        deps
    )

    const middleware = applyMiddleware(logicMiddleware)

    return createStore(rootReducer, composeEnhancers(middleware))
}

export function initStore(store) {
    store.dispatch({
        type: LOAD_CONFIG,
    })
}

const store = newStore()
initStore(store)

export default store
