import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { search } from "flows/users/actions"
import { setSearchTerm } from "flows/search/actions"
import Dropdown from "../Dropdown"
import Fact from "../Fact"

const facts = [
    {
        title: "Name",
        content:
      "Searches \"name\" field beginning with given search term. Also searches \"given_name\" and \"family_name\" in \"user_metadata\" with exact matches only.",
    },
    {
        title: "Email",
        content:
      "Wildcard search (beginning with or ending with). At least 3 characters are needed.",
    },
    {
        title: "Customer number",
        content: "Exact matches only.",
    },
    {
        title: "Company",
        content: "Exact matches only.",
    },
]

const InformationContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 60px;
  top: 0;
  right: 0;
  z-index: 1;
  .dropdown {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.4s;
  }
  &:hover {
    .dropdown {
      visibility: visible;
      opacity: 1;
    }
  }
`

const InformationSymbol = styled.div`
  ${InformationContainer}:hover & {
    opacity: 1;
  }
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #323e46;
  display: flex;
  font-weight: bold;
  height: 26px;
  width: 26px;
  opacity: 0.5;
  justify-content: center;
  pointer-events: none;
`

const Information = () => (
    <InformationContainer>
        <InformationSymbol>i</InformationSymbol>
        <Dropdown open right>
            <Fact facts={facts} />
        </Dropdown>
    </InformationContainer>
)

const SearchField = ({ search, searchTerm, setSearchTerm, isFieldEnabled }) => {
    const onChange = event => {
        const searchString = event.target.value
        setSearchTerm(searchString)
        search()
    }
    return (
        <div className="searchfield searchfield--transparent ">
            <input
                data-testid="searchfield-input"
                type="search"
                onChange={onChange}
                name="q"
                value={searchTerm}
                placeholder="Name, email, customer number, company"
                className="searchfield__input"
                autoComplete="off"
                disabled={!isFieldEnabled}
            />
            <Information />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        searchTerm: state.search.search_term,
        isFieldEnabled: state.roles_per_service.length > 0,
    }
}

const mapDispatchToProps = dispatch => ({
    search: () => dispatch(search()),
    setSearchTerm: searchTerm => dispatch(setSearchTerm(searchTerm)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchField)
